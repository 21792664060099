
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/user",
    "label": "Members",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/news",
    "label": "News",
    "icon": "pi pi-globe",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/newscategory",
    "label": "News Category",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/gallery",
    "label": "Gallery",
    "icon": "pi pi-images",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/election",
    "label": "Election",
    "icon": "pi pi-inbox",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/vote",
    "label": "Vote",
    "icon": "pi pi-thumbs-up-fill",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/votecategory",
    "label": "Vote Category",
    "icon": "pi pi-check-square",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/votesubcategory",
    "label": "Vote Subcategory",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/results",
    "label": "Results",
    "icon": "pi pi-chart-pie",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/candidate",
    "label": "Vote Candidates",
    "icon": "pi pi-user-edit",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/claims",
    "label": "Claims",
    "icon": "pi pi-exclamation-circle",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/support",
    "label": "Support",
    "icon": "pi pi-wrench",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/appsettings",
    "label": "App Settings",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/admin",
    "label": "Admin",
    "icon": "pi pi-shield",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/permissions",
    "label": "Permissions",
    "icon": "pi pi-lock",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles",
    "label": "Roles",
    "icon": "pi pi-user-plus",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/audits",
    "label": "Audits",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	claimsStatus: [    
{value: "Pending", label: "Pending"},
	{value: "Resolved", label: "Resolved"}
    ],
	newsType: [    
{value: "text", label: "text"},
	{value: "video", label: "video"}
    ],
	status: [    
{value: "Pending Verification", label: "Pending Verification"},
	{value: "Active", label: "Active"},
	{value: "InActive", label: "InActive"},
	{value: "Pending Deletion", label: "Pending Deletion"}
    ],
	idtype: [    
{value: "Ghana Card", label: "Ghana Card"},
	{value: "Passport", label: "Passport"},
	{value: "Voters ID", label: "Voters ID"}
    ],
	membershipCategory: [    
{value: "Expatriate Professional Player", label: "Expatriate Professional Player"},
	{value: "Retired Professional/Domestic Player", label: "Retired Professional/Domestic Player"},
	{value: "Premier League Player", label: "Premier League Player"},
	{value: "Semi Professional (Division 1,2,3)", label: "Semi Professional (Division 1,2,3)"},
	{value: "Elite Development/Academy Player", label: "Elite Development/Academy Player"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}